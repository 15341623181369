/* You can add global styles to this file, and also import other style files */

//== Vendor
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import '../node_modules/font-awesome/css/font-awesome.css';

@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

@import '../node_modules/simple-line-icons/css/simple-line-icons.css';

@import '../node_modules/loaders.css/loaders.css';

@import '../node_modules/ngx-toastr/toastr.css';

@import '../node_modules/jqcloud2/dist/jqcloud.css';

@import '../node_modules/summernote/dist/summernote.css';

@import '../node_modules/fullcalendar/dist/fullcalendar.min.css';

@import '../node_modules/codemirror/lib/codemirror.css';

@import '../node_modules/sweetalert/dist/sweetalert.css';

//Primefaces
//@import '../node_modules/primeicons/primeicons.css';//
@import '../node_modules/primeng/resources/themes/bootstrap/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';


//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";

.toast-top-center {
    margin-top: 5em;
}

.barra-busca {
    padding: 0;
}

// .barra-acao {
//     padding: 0;
// }

@media(max-width: 767px) {
    .barra-acao {
        padding-top: 1em;
    }
}

div.amcharts-chart-div a{
    position: unset !important;
    color: lightgrey !important;
}

.ngx-chips{
    border: 1px solid #dde6e9 !important;
    border-bottom: 1px solid #dde6e9;
    border-radius: 4px;
}

.ng2-tag-input{
  border: 1px solid #dde6e9 !important;
  border-bottom: 1px solid #dde6e9;
  border-radius: 4px;
}

.pb-lg{
    color: #005b9f;
}

.mytooltip{
  font-size: 14px;
}

.regularHeight {
  height: 28px;
}

#autocompleteCliente > .ui-autocomplete{
  width: 100% !important;
}

.ui-autocomplete{
  width: 100% !important;
  border-color: #cfdbe2;
  border-top-width: 3px;
}

.ui-autocomplete-input{
  width: 100% !important;
  border-color: #cfdbe2 !important;
  border-top-width: 3px;
  padding: 6px 10px !important;

  line-height: 1.52857143;
  color: #005695;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dde6e9;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}


